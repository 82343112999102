<template>
  <main id="news_item">
    <section class="bg-gray-medium text-white py-16">
      <div class="container">
        <div class="flex">
          <div class="w-full">
            <header-h2
              :content="post.title"
              casing="uppercase"
              align="center"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="bg-gray-medium pb-16">
      <div class="container">
        <div class="flex">
          <div class="w-full tablet:w-7/10 tablet:mx-auto px-4">
            <div class="news__item">

              <div class="news__content">
                <div v-html="post.content" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from "@/service/wordpress";

export default {
  name: "test",
  async asyncData({ params, app }) {
    const [post] = await Promise.all([wordpress.get(`page/1254`)])

    return {
      post: post.data
    }
  },
  data() {
    return {
      loggedIn: this.$auth.loggedIn,
      user: this.$auth.loggedIn ? this.$auth.user : {}
    }
  }
}
</script>

<style scoped>

</style>
